@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700;800&family=Raleway:wght@700;800;900&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
}

h1, h2, h3 {
    font-family: 'Raleway', sans-serif;
}

.page-header, .section-header {
    background-image: url("./images/heroimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.page-header {
    height: 30vh;
}

.section-header {
    height: 15vh;
}

.hero {
  background-image: url("./images/heroimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  height: 90vh;
  width: 100%;
  overflow: hidden;
}

.about-home {
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
}

.admissions  {
    -webkit-clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
        clip-path: polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%);
}

@media (max-width: 769px) {
    .contact-image {
        height: 30vh;
    }

    .page-header {
        height: 10vh;
    }

    .section-header {
        height: 8vh;
    }
}